// @import "../../../theme";
.menu-hot-key-box {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
}

.menu-tool-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  width: 100%;
  background-color: white;
}

.menu-title-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  background-color: white;
  position: absolute;
  border-bottom: 1px solid #F5F5F5;
  z-index: 10;
}

.menu-title-text-box {
  margin-left: 24px;
}

.menu-title-close-icon {
  width: 16px;
}

.menu-hot-key-title {
  font-size: 12px;
  color: #A3A3A3;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 8px;
}

.menu-close-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
}

.menu-tool-box-left {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  height: 24px;
  line-height: 24px;
}

.menu-tool-box-right {
  margin-right: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.menu-other-hot-box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.menu-hot-key-title {
  font-size: 12px;
  color: #A3A3A3;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 8px;
}

.menu-other-hot-box-word {
  height: 24px;
  border-radius: 4px;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
}

.menu-other-hot-box-plus {
  margin-right: 5px;
}

.menu-other-hot-out-box {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.menu-other-hot-out-box-mix {
  margin-right: 8px;
}

.menu-other-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  background-color: white;
}

.menu-other-box-left {
  margin-left: 24px;
}

.menu-other-array-box {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
}

.menu-tool-box-icon {
  height: 17px;
  opacity: 0.7;
}

.menu-tool-box-icon-box {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.menu-annex-box {
  width: 100%;
  height: 100%;
  background-color: #F6F6F6;
  overflow-y: auto;
}

.menu-add-page {
  margin-right: 16px;
}

.menu-under-btn-inner {
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #F5F5F5;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
  }
  img {
    width: 14px;
  }
}

.ppt-image {
  width: 192px;
  height: 112.5px;
  overflow: hidden;
}

.menu-under-btn-right {
  width: 42px;
  height: 42px;
}

.menu-under-btn {
  width: 100%;
  height: 42px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  border-top: 1px solid #F5F5F5;
  cursor: pointer;
  background-color: white;
  img {
    width: 14px;
    opacity: 0.6;
    margin-right: 12px;
  }
}

.menu-under-btn-right-inner {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;//@main_color;
}

.menu-page-box {
  width: 100%;
  height: 157.5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu-cell-box {
  width: 200px;
  height: 112.5px;
  /* Rectangle 11: */
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
  }
}

.page-out-box {
  width: 100%;
  height: 157.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  outline:none;
}

.page-out-box-active {
  width: 100%;
  height: 157.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DDDDDD;
  outline:none;
}

.page-box-inner-index-left {
  width: 32px;
  height: 112.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-mid-box {
  width: 192px;
  height: 112.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 6px;
  margin-left: 6px;
}

.page-box {
  width: 192px;
  height: 112.5px;
  background-color: white;

  > img {
    position: absolute;
    left: 49px;
    right: 0px;
    width: 192px;
    height: 112.5px;
  }
}

.page-box-inner-index-right {
  width: 20px;
  height: 112.5px;
}

.page-box-inner-index-delete {
  width: 24px;
  height: 24px;
  background-color: #eee;//@gray_lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  // .hover-transition;
  &:hover {
    // .hover-transition;
    background-color: #eee;//@gray_light;
  }
}

.page-box-inner-index-delete-box {
  width: 24px;
  height: 24px;
}

.page-box-close {
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 182px;
  margin-top: -10px;
}

.menu-close-btn {
  img {
    height: 14px;
    opacity: 0.6;
  }
}

.menu-title-close-icon {
  cursor: pointer;
  height: 14px;
  opacity: 0.6;
}
