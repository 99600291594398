.popover-box {
    width: 240px;
    height: 120px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 2px #999;
}

.popover-box-cell {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: #EBEBEB;
        transition-timing-function: ease-in-out;
        transition-duration: 200ms;
    }
}

.popover-box-cell-img-box {
    width: 80px;
    height: 40px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-box-cell-img {
    height: 40px;
}

.popover-box-cell-title {
    font-size: 12px;
    font-weight: bold;
}

.popover-box-cell-script {
    font-size: 11px;
    margin-left: 12px;
    margin-right: 12px;
    height: 36px;
    margin-top: 4px;
}

.icon-upload-file {
    img {
        width: 24px;
    } 
}
